<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import settingsService from '@/services/Settings/settingsService';

import { vueTopprogress } from 'vue-top-progress';
import { quillEditor } from 'vue-quill-editor';
import { cnpj } from '@/validate/customValidators.js';
import { withLog } from '../../logger/with_log';

// import Modal from '@/components/Modal';
// import pdfvuer from 'pdfvuer';
// import {DoubleBounce} from 'vue-loading-spinner';

export default {
  components: {
    vueTopprogress,
    quillEditor,
    // Modal,
    // DoubleBounce,
    // pdf: pdfvuer,
  },
  data() {
    return {
      contentTermosUso: '',
      contentTaxasPrazo: '',
      contentTermos4A: '',
      contentTermos4B: '',
      contentTermos4C: '',
      contentSocialAgent: '',
      editorCondicoesTermosUsoOption: {
        placeholder:
          'Descreva aqui as condições e termos de uso da plataforma.',
      },
      editorTaxasPrazosOption: {
        placeholder: 'Descreva aqui as taxas e prazos da plataforma.',
      },
      editorTermosAgenteOption: {
        placeholder: 'Descreva aqui os termos de serviço para Agente (API3).',
      },
      editorTermoUso4A: {
        placeholder: 'Descreva aqui os termos.',
      },
      editorTermoUso4B: {
        placeholder: 'Descreva aqui os termos.',
      },
      editorTermoUso4C: {
        placeholder: 'Descreva aqui os termos.',
      },
      settingsPublic: {
        overwriteMinGlobalInvestment: undefined,
      },
      settings: {
        safe2pay_api_keys: {},
        terms: {},
        emails: {},
      },
    };
  },
  validations: {
    settings: {
      cnpj: {
        valid: cnpj,
      },
    },
  },
  methods: {
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.contentTermosUso = html;
      this.settingsPublic.terms.condicoes_termos_uso = html;
    },
    onTaxasPrazoEditorChange({ html }) {
      this.contentTaxasPrazo = html;
      this.settingsPublic.terms.taxas_prazos = html;
    },
    onEditorAgentChange({ html }) {
      this.contentSocialAgent = html;
      this.settings.terms.agent_term = html;
    },
    onTermos4AEditorChange({ html }) {
      this.contentTermos4A = html;
      this.settings.termos_4a = html;
    },

    onTermos4BEditorChange({ html }) {
      this.contentTermos4B = html;
      this.settings.termos_4b = html;
    },

    onTermos4CEditorChange({ html }) {
      this.contentTermos4C = html;
      this.settings.termos_4c = html;
    },

    async loadSettings() {
      this.settingsPublic = await settingsService.getPublicSettings();
      var settings = await settingsService.getPrivateSettings();
      this.settings = {
        ...settings,
        emails: settings.emails || {},
        terms: settings.terms || {},
      };
    },
    checkSandboxAtivo() {
      this.$refs.marketplace_token_sandbox_active.click();
    },
    checkEmailSecure() {
      this.$refs.email_secure.click();
    },
    checkOverwriteGlobalInvestment() {
      this.$refs.overwriteMinGlobalInvestment.click();
    },
    checkValidarLimiteInvestimento() {
      this.$refs.validarLimiteInvestimento.click();
    },
    async save() {
      if (!this.$v.settings.cnpj.valid) {
        return this.$notify({
          title: 'Informe um CNPJ válido!',
          message: 'Informe um número de CNPJ válido.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 7000,
        });
      }

      this.$refs.topProgress.start();
      try {
        await settingsService.updatePublicSettings(this.settingsPublic);
        await settingsService.updatePrivateSettings(this.settings);

        this.$refs.topProgress.done();
        this.$notify({
          title: 'Configurações atualizadas!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill;
    },
  },
  mounted() {
    this.loadSettings();
  },
};
</script>

<template>
  <div class="add-user">
    <form @submit.prevent="save()">
      <card>
        <h5 slot="header" class="title">Configurações Gerais</h5>

        <div class="row">
          <div class="col-md-3 mt-3">
            <div class="form-group">
              <base-input
                label="CNPJ da Plataforma"
                placeholder="Informe o CNPJ"
                v-model="settings.cnpj"
              >
              </base-input>
              <div
                class="error"
                v-if="settings.cnpj != '' && $v.settings.cnpj.$invalid"
              >
                Informe um CNPJ válido.
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Nome da empresa da Plataforma"
              placeholder="Informe o nome da empresa"
              v-model="settings.companyName"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Nome da Plataforma"
              placeholder="Informe o nome da plataforma"
              v-model="settings.platformName"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Identificação da Plataforma"
              placeholder="Informe a identificação"
              v-model="settings.platformIdentifier"
            >
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 mt-3">
            <base-input
              label="Site da Plataforma"
              placeholder="Informe o site"
              v-model="settings.site"
            >
            </base-input>
          </div>

          <div class="col-md-3 mt-3">
            <label> Investimento Mínimo Permitido </label>
            <currency-input
              placeholder="Informe o valor mínimo"
              v-model="settingsPublic.minGlobalInvestment"
              currency="BRL"
              locale="pt-br"
              :auto-decimal-mode="true"
              class="form-control"
            />
          </div>

          <div class="col-md-5 mt-5">
            <input
              name="overwriteMinGlobalInvestment"
              ref="overwriteMinGlobalInvestment"
              type="checkbox"
              v-model="settingsPublic.overwriteMinGlobalInvestment"
            />
            <label
              for="overwriteMinGlobalInvestment"
              style="margin-left: 5px"
              @click="checkOverwriteGlobalInvestment"
            >
              Permitir Sobrescrever Investimento Mínimo Permitido</label
            >
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 mt-3">
            <base-input
              label="Material Didático"
              placeholder="Informe o link"
              v-model="settings.link_material_didatico"
            >
            </base-input>
          </div>
          <div class="col-md-5 mt-5">
            <input
              name="validarLimiteInvestimento"
              ref="validarLimiteInvestimento"
              type="checkbox"
              v-model="settingsPublic.validar_limite_investimento"
            />
            <label
              for="validarLimiteInvestimento"
              style="margin-left: 5px"
              @click="checkValidarLimiteInvestimento"
            >
              Travar investimentos maiores que o limite</label
            >
          </div>
        </div>
      </card>

      <card>
        <h5 slot="header" class="title">Condições, Termos, Taxas e Prazos</h5>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Condições e Termos de Uso</label>
            <quill-editor
              ref="quillEditor"
              v-model="settingsPublic.terms.condicoes_termos_uso"
              :options="editorCondicoesTermosUsoOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onEditorChange($event)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Taxas e Prazos</label>
            <quill-editor
              ref="quillEditor"
              v-model="settingsPublic.terms.taxas_prazos"
              :options="editorTaxasPrazosOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onTaxasPrazoEditorChange($event)"
            />
          </div>
        </div>
      </card>

      <card>
        <h5 slot="header" class="title">Agente (API3)</h5>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Termos de Parceria de Serviço</label>
            <quill-editor
              ref="quillEditor"
              v-model="settingsPublic.terms.agent_term"
              :options="editorTermosAgenteOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onEditorAgentChange($event)"
            />
          </div>
        </div>
      </card>

      <card>
        <h5 slot="header" class="title">Termos de adesão e ciência de risco</h5>
        <h5 slot="header" class="subtitle">
          <b>Variáveis:</b> #data, #local, #nomeInvestidor, #cpfInvestidor,
          #razaoSocialEmissor, #montante
        </h5>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Autodeclaração - Anexo 4-A</label>
            <quill-editor
              ref="quillEditor"
              v-model="settings.termos_4a"
              :options="editorTermoUso4A"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onTermos4AEditorChange($event)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Autodeclaração - Anexo 4-B</label>
            <quill-editor
              ref="quillEditor"
              v-model="settings.termos_4b"
              :options="editorTermoUso4B"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onTermos4BEditorChange($event)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Autodeclaração - Anexo 4-C</label>
            <quill-editor
              ref="quillEditor"
              v-model="settings.termos_4c"
              :options="editorTermoUso4C"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onTermos4CEditorChange($event)"
            />
          </div>
        </div>
      </card>

      <card>
        <h5 slot="header" class="title">Tokens Marketplace</h5>

        <div class="row">
          <div class="col-md-5 mt-3">
            <base-input
              label="Token Marketplace Produtivo"
              placeholder="Informe o token produtivo do Marketplace"
              v-model="settings.safe2pay_api_keys.marketplace_token_prd"
            >
            </base-input>
          </div>
          <div class="col-md-5 mt-3">
            <base-input
              label="Token Marketplace Sandox"
              placeholder="Informe o token sandbox do Marketplace"
              v-model="settings.safe2pay_api_keys.marketplace_token_sandbox"
            >
            </base-input>
          </div>
          <div class="col-md-2 mt-3">
            <input
              name="marketplace_token_sandbox_active"
              ref="marketplace_token_sandbox_active"
              type="checkbox"
              v-model="
                settings.safe2pay_api_keys.marketplace_token_sandbox_active
              "
            />
            <label
              for="marketplace_token_sandbox_active"
              style="margin-left: 5px; margin-top: 36px"
              @click="checkSandboxAtivo"
            >
              Sandbox Ativo</label
            >
          </div>
        </div>
      </card>

      <card>
        <h5 slot="header" class="title">Configurações de E-mails</h5>

        <div class="row">
          <div class="col-md-3 mt-3">
            <base-input
              label="Host"
              placeholder="Informe o host"
              v-model="settings.emails.host"
            >
            </base-input>
          </div>
          <div class="col-md-2 mt-3">
            <base-input
              label="Porta"
              placeholder="Informe a porta"
              v-model="settings.emails.porta"
              v-mask="'####'"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Usuário"
              placeholder="Informe o usuário"
              v-model="settings.emails.usuario"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Senha"
              placeholder="Informe a senha"
              v-model="settings.emails.senha"
            >
            </base-input>
          </div>
          <div class="col-md-1 mt-3">
            <div class="form-group">
              <label class="control-label"> Secure </label>
              <input
                class="form-control"
                style="
                  width: 20px;
                  height: 20px;
                  margin-left: 5px;
                  margin-top: 10px;
                "
                ref="email_secure"
                type="checkbox"
                v-model="settings.emails.secure"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 mt-3">
            <base-input
              label="'From' dos e-mails"
              placeholder="Informe aqui"
              v-model="settings.emails.from"
            >
            </base-input>
          </div>
          <div class="col-md-4 mt-3">
            <base-input
              label="Título dos e-mails"
              placeholder="Informe o título"
              v-model="settings.emails.titulo"
            >
            </base-input>
          </div>
          <div class="col-md-4 mt-3">
            <base-input
              label="Rodapé dos e-mails"
              placeholder="Informe o rodapé"
              v-model="settings.emails.rodape"
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 mt-3">
            <div class="form-group">
              <label class="control-label"> Aviso acima do Rodapé </label>
              <textarea
                type="text"
                cols="100"
                rows="5"
                class="form-control"
                placeholder="Informe o aviso"
                style="border: 1px solid #ccc; border-radius: 10px"
                v-model="settings.emails.aviso_acima_rodape"
              >
              </textarea>
            </div>
          </div>
          <div class="col-md-2 mt-3">
            <base-input
              label="Cor do e-mail (hex)"
              placeholder="Ex: #288cf7"
              v-model="settings.emails.cor"
            >
            </base-input>
          </div>
          <div class="col-md-2 mt-3">
            <base-input
              label="Cor da fonte (hex)"
              placeholder="Ex: #ffffff"
              v-model="settings.emails.cor_fonte"
            >
            </base-input>
          </div>
        </div>
      </card>

      <card>
        <button type="submit" class="btn btn-sm mt-3">
          Salvar configurações
        </button>
      </card>
    </form>
    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
